@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  --primary-color: #2C2D98;
  --texthighlight-color: #DF6E34;
  --tertiary-color: #252525;
  --secondary-color: #ffffff;
  --gray-color: #F5F5F5;
  --grayshade2-color: #ECECEC;
  --grayshade3-color: #D9D9D9;
  --graydark-color: #5A5A61;
  --disabled-color: #C0C0C0;
  --notice-color: #C91414;
} */

:root {
  --brand-color: var(--brand-color);
  --primary-color: var(--primary-color);
  --texthighlight-color: var(--texthighlight-color);
  --tertiary-color: var(--tertiary-color);
  --secondary-color: var(--secondary-color);
  --gray-color: var(--gray-color);
  --grayshade2-color: var(--grayshade2-color);
  --grayshade3-color: var(--grayshade3-color);
  --graydark-color: var(--graydark-color);
  --disabled-color: var(--disabled-color);
  --notice-color: var(--notice-color);
  --lightBlue-color: var(--lightBlue-color);
  --white-color: var(--white-color);
  --grayshade4-color: var(--grayshade4-color);
  --grayshade5-color: var(--grayshade5-color);
  --btnhover: var(--btnhover);
  --btndisable: var(--btndisable);
  --loaderbg: var(--loaderbg);
  --radioBorder: var(--radioBorder);
  --menuhover-color: var(--menuhover-color);
  --imgopacity: var(--imgopacity);
  --lightblue-color: var(--lightblue-color);
  --popupBtncolor: var(--popupBtncolor);
  --grey-highlight: var(--grey-highlight);
  --blueLinkColor: var(--blueLinkColor);
  --lightGreyMessage: var(--lightGreyMessage);
  --chatLoaderone-color: var(--chatLoaderone-color);
  --chatLoadertwo-color: var(--chatLoadertwo-color);
  --grayshade6-color: var(--grayshade6-color);
}
/* :root {
  --brand-color: #2C2D98;
    --primary-color: #A3A4F2;
    --texthighlight-color: #DF6E34;
    --tertiary-color: #FFFFFF;
    --secondary-color: #252525;
    --gray-color: #161616;
    --grayshade2-color: #1B1B1B;
    --grayshade3-color: #0A0A0A;
    --graydark-color: #9F9F9F;
    --disabled-color: #C0C0C0;
    --notice-color: #C91414;
    --lightBlue-color: #0A0A0A;
    --white-color: #ffffff;
    --grayshade4-color: #393939;
    --grayshade5-color: #0a0a0a;
    --btnhover:#1e40af;
    --btndisable:#a3a4f280;
    --loaderbg:#00000000;
    --radioBorder:#9F9F9F;
    --menuhover-color:#ffffff;
    --imgopacity:0.3;
    --lightblue-color:#c9c9c9;
    --popupBtncolor: #a9a9a9;
} */

/* :root[data-theme= "dark"] {
  --brand-color: #2C2D98;
    --primary-color: #A3A4F2;
    --texthighlight-color: #DF6E34;
    --tertiary-color: #F6F6F6;
    --secondary-color: #252525;
    --gray-color: #161616;
    --grayshade2-color: #1B1B1B;
    --grayshade3-color: #0A0A0A;
    --graydark-color: #9F9F9F;
    --disabled-color: #C0C0C0;
    --notice-color: #C91414;
    --lightBlue-color: #0A0A0A;
    --white-color: #ffffff;
    --grayshade4-color: #393939;
    --grayshade5-color: #0a0a0a;
    --btnhover:#1e40af;
    --btndisable:#a3a4f280;
    --loaderbg:#00000000;
    --radioBorder:#9F9F9F;
    --menuhover-color:#ffffff;
    --imgopacity:0.3;
    --lightblue-color:#c9c9c9;
    --popupBtncolor: #393939;
    --grayshade6-color:#7c7c7c;
    --grey-highlight: #3a3a3a;
    --blueLinkColor:#8D8EF3;
    --lightGreyMessage:#3A3A3A;
    --chatLoaderone-color: #a3a4f27d;
    --chatLoadertwo-color: #a3a4f230;
}
:root[data-theme= "light"] {
  --brand-color: #2C2D98;
  --primary-color: #2C2D98;
  --texthighlight-color: #DF6E34;
  --tertiary-color: #252525;
  --secondary-color: #ffffff;
  --gray-color: #F5F5F5;
  --grayshade2-color: #ECECEC;
  --grayshade3-color: #D9D9D9;
  --graydark-color: #5A5A61;
  --disabled-color: #C0C0C0;
  --notice-color: #C91414;
  --lightBlue-color: #EBEBFF;
  --white-color: #ffffff;
  --grayshade4-color: #d3d3d3;
  --grayshade5-color: #E0E0E0;
  --btnhover:#1e40af;
  --btndisable: #6b6cd1;
  --loaderbg:#ffffffb3;
  --radioBorder:#d7d7d7;
  --menuhover-color:#2C2D98;
  --imgopacity:1;
  --lightblue-color:#EFF6FF;
  --popupBtncolor: #a9a9a9;
  --grayshade6-color:#CECECE;
  --grey-highlight: #f0f0f0;
  --blueLinkColor:#263FA9;
  --lightGreyMessage:#F0F0F0;
  --chatLoaderone-color: #2c2d9830;
  --chatLoadertwo-color: #2c2e9871;
  --grayshade6-color:#CECECE;
} */
/* @media(prefers-color-scheme: dark) {
  :root {
    --brand-color: #2C2D98;
    --primary-color: #A3A4F2;
    --texthighlight-color: #DF6E34;
    --tertiary-color: #F6F6F6;
    --secondary-color: #252525;
    --gray-color: #161616;
    --grayshade2-color: #1B1B1B;
    --grayshade3-color: #0A0A0A;
    --graydark-color: #9F9F9F;
    --disabled-color: #C0C0C0;
    --notice-color: #C91414;
    --lightBlue-color: #0A0A0A;
    --white-color: #ffffff;
    --grayshade4-color: #393939;
    --grayshade5-color: #0a0a0a;
    --btnhover:#1e40af;
    --btndisable:#a3a4f280;
    --loaderbg:#00000000;
    --radioBorder:#9F9F9F;
    --menuhover-color:#ffffff;
    --imgopacity:0.3;
    --lightblue-color:#c9c9c9;
    --popupBtncolor: #393939;
    --grayshade6-color:#7c7c7c;
    --blueLinkColor:#8D8EF3;
    --lightGreyMessage:#3A3A3A;
    --chatLoaderone-color: #a3a4f27d;
    --chatLoadertwo-color: #a3a4f230;
  }
} */

@font-face {
  font-family: 'icomoon';
  src:  url('/public/assets/fonts/icomoon.eot?oxm9o3');
  src:  url('/public/assets/fonts/icomoon.eot?oxm9o3#iefix') format('embedded-opentype'),
    url('/public/assets/fonts/icomoon.ttf?oxm9o3') format('truetype'),
    url('/public/assets/fonts/icomoon.woff?oxm9o3') format('woff'),
    url('/public/assets/fonts/icomoon.svg?oxm9o3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'icomoon';
  src:  url('/public/assets/fileformat-font/fonts/icomoon.eot?7cdy0p');
  src:  url('/public/assets/fileformat-font/fonts/icomoon.eot?7cdy0p#iefix') format('embedded-opentype'),
    url('/public/assets/fileformat-font/fonts/icomoon.ttf?7cdy0p') format('truetype'),
    url('/public/assets/fileformat-font/fonts/icomoon.woff?7cdy0p') format('woff'),
    url('/public/assets/fileformat-font/fonts/icomoon.svg?7cdy0p#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon';
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home-active:before {
  content: "\e92a";
}
.icon-home2-active:before {
  content: "\e932";
}
.icon-home-inactive:before {
  content: "\e92b";
}
.icon-library-active:before {
  content: "\e92c";
}
.icon-library-inactive:before {
  content: "\e92d";
}
.icon-profile-active:before {
  content: "\e92e";
}
.icon-profile-inactive:before {
  content: "\e92f";
}
.icon-search-active:before {
  content: "\e930";
}
.icon-search-inactive:before {
  content: "\e931";
}
.icon-feature_group .path1:before {
  content: "\e91a";
}
.icon-feature_group .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-feature_range .path1:before {
  content: "\e91c";
}
.icon-feature_range .path2:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-feature_range .path3:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-feature_range .path4:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-feature_range .path5:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-feature_lock2 .path1:before {
  content: "\e921";
}
.icon-feature_lock2 .path2:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-feature_share .path1:before {
  content: "\e923";
}
.icon-feature_share .path2:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-feature_insight .path1:before {
  content: "\e925";
}
.icon-feature_insight .path2:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-feature_pdf .path1:before {
  content: "\e927";
}
.icon-feature_pdf .path2:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-rightLinkArrow:before {
  content: "\e929";
}

.icon-addpeopleIcon:before {
  content: "\e900";
}
.icon-bellIcon:before {
  content: "\e901";
}
.icon-cameraIcon:before {
  content: "\e902";
}
.icon-closeIcon:before {
  content: "✕";
}
.icon-cloudIcon:before {
  content: "\e904";
}
.icon-earphoneIcon:before {
  content: "\e905";
}
.icon-editIcon:before {
  content: "\e906";
}
.icon-fileattachIcon:before {
  content: "\e907";
}
.icon-graphIcon:before {
  content: "\e908";
}
.icon-heartIcon .path1:before {
  content: "\e909";
  color: rgb(255, 255, 255);
}
.icon-heartIcon .path2:before {
  content: "\e90a";
  margin-left: -1em;
}
.icon-heartIcon .path3:before {
  content: "\e90b";
  margin-left: -1em;
}
.icon-leftarrowIcon:before {
  content: "\e90c";
}
.icon-messageIcon:before {
  content: "\e90d";
}
.icon-messagelistIcon:before {
  content: "\e90e";
}
.icon-networkIcon:before {
  content: "\e90f";
}
.icon-phoneIcon:before {
  content: "\e910";
}
.icon-questionmarkIcon:before {
  content: "\e911";
}
.icon-rightarrowIcon:before {
  content: "\e912";
}
.icon-seatchIcon:before {
  content: "\e913";
}
.icon-sendIcon:before {
  content: "\e914";
}
.icon-settingIcon:before {
  content: "\e915";
}
.icon-shareIcon:before {
  content: "\e916";
}
.icon-starIcon:before {
  content: "\e917";
  color: #f2a000;
}
.icon-suggestionIcon:before {
  content: "\e918";
}
.icon-uploadIcon:before {
  content: "\e919";
}
.icon-rightLink:before {
  content: '>';
}
.icon-pptx-icon-1:before {
  content: "\e100";
}
.icon-docx-icon-1:before {
  content: "\e101";
}
.icon-xml-icon-1:before {
  content: "\e102";
}
.icon-pages-icon-1:before {
  content: "\e103";
}
.icon-epub-icon-1 .path1:before {
  content: "\e104";
  color: rgb(228, 228, 228);
}
.icon-epub-icon-1 .path2:before {
  content: "\e105";
  margin-left: -1em;
  color: rgb(51, 62, 72);
}
.icon-epub-icon-1 .path3:before {
  content: "\e106";
  margin-left: -1em;
  color: rgb(51, 62, 72);
}
.icon-epub-icon-1 .path4:before {
  content: "\e107";
  margin-left: -1em;
  color: rgb(209, 211, 211);
}
.icon-epub-icon-1 .path5:before {
  content: "\e108";
  margin-left: -1em;
  color: rgb(51, 62, 72);
}
.icon-rtf-icon-1:before {
  content: "\e109";
}
.icon-csv-icon-1:before {
  content: "\e110a";
}
.icon-bi_file-pdf:before {
  content: "\e111b";
}

html, body, #root, #root>div {
  height: 100%;
}
/* prevent pinch-zoom for Chrome 36+, Safari 13+ */
html {
  touch-action: pan-x pan-y;
  min-height: 100%; /* prevent pinch-zoom at page bottom */
}
/* prevent pull-to-refresh for Safari 16+ */
@media screen and (pointer: coarse) {
  @supports (-webkit-backdrop-filter: blur(1px)) and (overscroll-behavior-y: none)  {
    html {
      min-height: 100.3%;
      overscroll-behavior-y: none;
    }
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img{
  /* width:300px; */
}

ol,
ul {
  list-style: inherit;
  margin: inherit;
  padding: inherit;
}
button{
  border: none;
  color: var(--secondary-color);
  padding: 14px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius:2px;
  cursor: pointer;
  background-color: #424242;
}

input{
  width: 30%;
  padding: 6px;
  border:  1px solid #E7E7E7;
  outline:none;
}


p {
  margin: 0 0 8px 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container{
  height:100%;
  background:var(--secondary-color);
  display: flex;
}

.channel-type{
  padding: 12px 16px;
  color: #6210CC;
}
@media(min-width:768px){
  .channel-type{
      z-index: 1;
  }
}
@media(max-width:767px){
  .channel-type{
    padding: 12px 18px; 
  }
}

@media(max-width:500px){
  .channel-type .explore-btn {
    background: #DDDFE5;
    color: var(--primary-color);
    align-items: center;
    border: 1px solid var(--primary-color);
  }
  .channel-type .create-btn {
    align-items: center;
  }
  .channel-type .explore-btn img,  .channel-type .create-btn img{
    margin-left: 10px;
  }
}

.group-card {
  width: fit-content;
  padding: 15px;
  margin-top: 20px;
  border: 1px solid var(--secondary-color);
  border-radius: 10px;
}

.card {
  width: fit-content;
  padding: 15px;
  margin-left: 60px;
  border: 1px solid black;
  border-radius: 10px;
}

.card-name {
  font-weight: bold;
}

.channel-list {
  background: var(--secondary-color);
  flex: 1;
  border-right: 1px solid #cbcbcb;
  /* overflow-y: auto; */
  height: 100%;
  transition: 0.3s all;
  width: 25%;
}
@media(min-width:768px) and (max-width:990px){
  .channel-list {
    width: 40%;
  }
}
@media(min-width:991px) and (max-width:1024px){
  .channel-list {
    width: 32%;
  }
}
.channel-list-ct {
  height: 72vh;
  overflow: auto;
  padding-bottom: 75px;
}

.channel-list-without-search {
  height: 80vh;
  overflow: auto;
  padding-bottom: 0px;
}
@media(min-width:1025px){
  .channel-list-ct {
    overflow-y: scroll;
  }
  .channel-list-ct::-webkit-scrollbar {
    background: var(--secondary-color); 
    width: 10px;
  }
  .channel-list-ct::-webkit-scrollbar-thumb {
    background-color: var(--secondary-color);
  }
  .channel-list-ct:hover::-webkit-scrollbar-thumb {
    background-color: #cacaca;
  }
}
@media(max-width:767px){
  .channel-list-ct {
    height: 80%;
    overflow: auto;
    
  }
  .channel-list {
    flex: none;
    width: 100%;
  }
  .channel-list-ct {
    height: 85%;
    padding: 0px 0 130px;
  }
}
@media(max-width:375px){
  .channel-list-ct {
    height: 75%;
  }
}
@media(max-width:320px){
  .channel-list-ct {
    height: 70%;
  }
}

.channel-list #search-box{
  border-radius: 4px;
  padding: 10px 15px ;
  width: 86%;
  margin-left: -16px;
}
@media (min-width:1025px){
  .channel-list #search-box{
    width: 88%;
    margin-right:8px;
    margin-left: 0px;
    background: var(--secondary-color);
    border-radius: 8px;
    color: var(--tertiary-color);
  }

  .channel-list #search-box::placeholder{
    color: #637381;
  }
 
}

@media(max-width:767px){
  .channel-list .search-header{
    margin: 20px auto 15px; 
  }
}
@media(max-width:1024px){
  .channel-list #search-box{
    width: 90%;
    margin-left: 0px;
  }
}

.channel-list-item{
  background: var(--grayshade2-color);
  padding:8px 12px;
  border-top: 1px solid var(--grayshade2-color);
  display:flex;
  justify-content: space-between;
  align-items: center;
  min-height: 42px;
  color: var(--tertiary-color);
  width: 90%;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  border-radius: 4px;
}
.channel-list-item.active{
  background: var(--grayshade4-color);
}

.channel-list-item.active:hover{
  background: var(--grayshade4-color);
}

.channel-list-item:hover{
  background: var(--grayshade4-color);;
}

.channel-list-item:hover .channel-icon{
  visibility: visible;
}
.channel-list-item:last-child{
  margin-bottom: 40px;
}
.sort-channels {
  width: 100%;
  font-size: 16px;
  padding: 7px;
  cursor: pointer;
}

.unarchive {
  transform: rotate(180deg);
}

.archived-channel {
  padding: 10px;
}

.last-message{
  font-weight:400;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 230px;
    display: -webkit-box;
    white-space: pre-line;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.channel-list-item-name{
  font-weight: 600;
  cursor: pointer;
  overflow: hidden;
  max-width: 75ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex:1;
}

.channel-list-item-sup {
  margin: 0 0 0 5px;
  font-size: 10px;
}

.channel-icon{
  visibility:hidden;
  width: 20px;
}

.breadrumbs{
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  color: var(--graydark-color);
}

.breadrumbs a{
  margin: 0 5px;
}

.breadrumbs .active{
  font-weight: 700;
  margin: 0 5px;
}

.channel{
  height: 100%;
  background: var(--secondary-color);
  /* width: 75%; */
  flex: 3;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  /* z-index: 2; */
  /* overflow-y: scroll; */
  /* margin-bottom: 75px; */
}
@media(min-width:768px) and (max-width:1024px){
  /* .channel {
    width: 85%;
  } */
}
/* @media(min-width:991px) and (max-width:1024px){
  .channel-list {
    width: 32%;
  }
} */

.bot-name-wrapper .shareIcon{
  display: none;
  transform: translate(70px, 25px);
}
@media(max-width:767px){
  .channel-list {
    z-index: 1;
    position: fixed !important;
    height: 100%;
  }
  .bot-name-wrapper .shareIcon{
    transform: translate(40px, 25px);
  }
  /* .channel {
    flex: none;
    width: 100%;
    min-height: 100%;
    position: relative;
    z-index: 1;
    margin-left: 0%;
    position: absolute;
    margin-top: 80px;
  } */
  /* .chatbot-dashboard {
    
    z-index: 0;
  } */
  .chatbot-dashboard {
    /* height: calc(100vh - 80px)!important; */
  }
  .channel{
    min-height: calc(100vh - 80px) !important;
    height: auto !important;
    padding-bottom: 70px;
    z-index: initial !important;
    background-color: var(--secondary-color);
    z-index: initial !important;
  }
}
.channel .mb-logo-section, .channel-list .mb-logo-section{
  display: none;
}

.public-explore-heading{
  display: flex;
  background: var(--primary-color);
  align-items: center;
  justify-content: space-between;
  padding: 12px 1.7rem;
  position: relative;
  width: 100vw;
  position: sticky;
  top: 0px;
}
@media(max-width:500px){
  .public-explore-heading{
    display: none;
  }
  .bot-name-wrapper .shareIcon{
    transform: translate(40px, 10px);
  }
}

@media(max-width:380px){
  .bot-name-wrapper .shareIcon{
    transform: translate(20px, 10px);
  }
}

.public-explore-heading .brainstrom-logo{
  width: 20%;
}

@media(max-width:500px){
  .channel .mb-logo-section, .channel-list .mb-logo-section{
    display: none;
    background: var(--primary-color);
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    position: relative;
    width: 100vw;
  }
  /* .channel h1{
    padding: 20px 16px 0 10px;
  } */
  .channel-list {
    border-right: none;
  }
  .channel-list .mb-logo-section .mb-heading, .channel .mb-logo-section .mb-heading{
    /* font-size: 22px; */
    padding: 1.5px 0;
  }
}
.channel-fixed-header {
  margin-top: 160px;
}


.how-to-use ul li{
  list-style: none;
}
.thread {
  max-width: 35vw;
}

.thread, .thread-input {
  border-left: 1px solid lightgray;
}

/* .underline {
  width: 100%;
  border-bottom: 1px solid lightgray;
} */

.threads {
  max-width: 35vw;
}

.threads, .threads-input {
  border-left: 1px solid lightgray;
}

/* .underline {
  width: 100%;
  border-bottom: 1px solid lightgray;
} */

.channel-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leave-channel{
  /* margin-left: 12px; */
  padding: 8px;
}

.freeze-channel {
  margin-right: 12px;
}

.freeze-channel input {
  width: auto;
}

.message-list{
  /* height: 100%; */
  /* display: flex; */
  flex-direction: column;
  overflow-y: scroll;
  height: 90%;
  padding-bottom: 0px;
  background:var(--secondary-color);
  /* padding: 0 30px; */
}

.message-list .message-item img{
  max-width: 300px;
}

@media(max-width:767px){
  .message-list .message-item img{
    max-width:100%;
  }
}

@media(max-width:767px){
  .message-list{
    height: calc(100% - 20px);
    padding-top: 75px;
    padding-bottom: 40px !important;
  }
  /* .message-list {
    padding-bottom: 100px;
    padding-top: 65px;
  } */
}

@media(max-width:375px){
  .message-list{
    height: 100%;
    padding-top: 75px;
  }
  /* .message-list {
    padding-bottom: 100px;
    padding-top: 65px;
  } */
}

@media screen and (max-width: 1023px){
  .message-list{
    padding: 0 5px;
  }
}

.message-item{
  margin: 12px;
  display:flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  /* var(--secondary-color)-space: pre-line; */
  word-wrap: break-word;
}

.message.message-from-you{
  margin-left: auto;
  background: var(--lightGreyMessage);
  flex-direction: row;
  margin-right: 12px;
}

.message-item.message-from-you{
  flex-direction: row;
}

.oc-message, .oc-message-og-tags{
  padding:12px;
  display:flex;
}

.oc-message-og-tags {
  flex-direction: column;
  max-width: 50%;
  padding: 5px;
  margin-left: 7vw;
  margin-top: -10px;
  box-sizing: border-box;
  border: 1px solid black;
}

.oc-message, .message-og-tags {
  padding:12px 0px;
  display:flex;
}

.message-og-tags {
  flex-direction: column;
  padding: 5px;
  box-sizing: border-box;
}

.og-tags-title {
  margin: 0 0 10px;
}

.og-tags-description, .og-tags-url, .metadata-modal-list {
  margin: 0 0 5px;
}

.og-tags-img, .show-users-status-btn, .members-invite-btn {
  width: 100%;
}

.oc-admin-message {
  background: rgba(255, 0, 0, 0.05);
}

.report-notification {
  background: rgba(0, 255, 0, 0.5);
  color: var(--secondary-color);
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin: 5px 0;
  padding: 5px;
}

.message{
  border-radius: 8px;
  width:70%;
  background: var(--lightGreyMessage);
  color:var(--tertiary-color);
  padding: 10px 15px;
  margin-left: 12px;
  /* overflow-x: auto; */
  width: auto;
  max-width: 90%;
  position: relative;
}

.message::before{
  content: "";
  position: absolute;
  /* border-right: 0px solid transparent; */
  border-left: 15px solid transparent;
  border-top: 15px solid var(--lightGreyMessage);
  transform: rotate(100deg);
  left: -12px;
  bottom: 8px;
}

.message-from-you::before{
  transform: rotate(180deg) !important;
  right: -12px !important;
  left: auto !important;
}

@media(max-width:767px){ 
  .message{
    padding: 5 15px;
  }
}
@media(max-width:767px){ 
  .message{
    width: 100%;
    margin: 0 !important
  }
} 

.admin-message {
  background: rgba(255, 0, 0, 0.05);
  width: 100%;
  color: black;
  margin-left: 0;
}

.message-info{
  /* margin-bottom:4px; */
  display: flex;
  justify-content: space-between;
  line-height: 24px;
}

.message.message-from-you a, .message-item .message a {
  color: var(--blueLinkColor);
}

.message-item .message ul {
  margin:10px 25px;
}

.message-item .message li {
  list-style: number;
}


.message-icon{
  visibility:hidden;
  width: 18px;
  /* filter: brightness(0) invert(1); */
}
@media(max-width:767px){
  .message-icon {
    width: 14px;
  }
}

.oc-message-icon, .oc-channel-list-icon{
  visibility:hidden;
  width: 14px;
}


.message:hover .message-icon, .channel-list-item:hover .oc-channel-list-icon, .member-item:hover .message-icon, .double_tick-icon, .member-item-wrapper .message-icon {
  visibility: visible;
}

.double_tick-icon-read {
  filter: none;
}

.mute-button {
  margin-top: 3px;
}

.member-item .message-icon {
  filter: none;
}

.oc-document-message, .message-user-info, .react-button-wrapper, .reactions-list, .metadata-modal-list, .create-channel-wrapper {
  display: flex;
}

.reactions-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  background: var(--secondary-color);
  border-radius: 8px;
}

.reactions-list li {
  margin-right: 2px;
  padding: 4px;
}

.react-button-wrapper {
  margin: 5px 5px 0 0;
  width: 100%;
  justify-content: flex-end;
}

.react-button-img {
  width: 20px;
  filter: none;
}

.reactions {
  margin: 5px 0 0;
}

.reactions-item {
  cursor: pointer;
  margin-right: 5px;
}

.reactions-item-inner {
  font-size: 10px;
}

.oc-document-message-icon {
  width: 24px;
}

.oc-message:hover .oc-message-icon, .message-input .oc-message-icon, .metadata-modal-list .oc-message-icon {
  visibility: visible;
}

.oc-message-sender-name{
  color:#6210CC;
  margin-left:4px;
  font-weight: 600;
  margin-right:4px;
}

.message-sender-name{
  font-weight: 600;
  margin-right:4px;
}

.channel-header{
  padding: 17px 12px 15px 0;
  font-size: 24px;
  font-weight: 800;
  position: sticky;
  top: 0;
  background: var(--secondary-color);
  z-index: 69;
}
@media(max-width:767px){
  .channel-header {
    position: fixed;
    top: 0;
    width: 100%;
    left:0
  }
}

/* code by ayaz */
@media(max-width:600px){
  .channel-header .channel-name, .channel-header .header-name-section{
    flex: 1;
  }
  
  .channel-header .header-back-arrow{
    width: 48px;
    height: auto;
  }
  .channel-header .header-right-icons{
    margin-left: 8px;
  }
}

.channel-header-wrapper {
  position: fixed;
  width: 100%;
  top: 0;
}

.members-list{
  border-left: 1px solid #cbcbcb;
  padding: 12px;
  width: 250px;
  /* margin-top: 80px; */
  background-color: var(--gray-color);
  overflow-y: auto;
  padding-bottom: 10px;
}
@media(max-width:767px){
  .members-list {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    background-color: var(--gray-color);
    transition: 1s all;
  }
}
@media(min-width:768px) and (max-width:920px){
  .members-list {
    width: 210px;
  }
}

.member-item{
  /* padding: 12px 8px; */
  padding: 12px 25px;
  cursor: pointer;
  font-weight: 600;
  /* border-bottom: 1px solid #cfcfcf; */
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
  position: relative;
}

.member-item .creator-tag{
  font-size: 11px;
  color: #008000;
  background: var(--secondary-color);
  padding: 3px 5px;
  border-radius: 5px;
  border: 1px solid #008000;
  transform: translateX(15px);
}

.member-item.online::before,.member-item.offline::before{
  content: '';
  background: green;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}

.member-item.offline::before{
  background: red;
}

/* .member-item p{
  position: relative;
}

.member-item .online{
  right: -20px;
  background: green;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  top: -3px;
} */

.member-item-wrapper {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.member-item:hover{
  background: var(--grayshade4-color);
}

.member-item-name{
  margin-left:12px;
}

.member-list-btn {
  margin: 0 0 5px;
}

.member-selected{
  color: #62EEAB;
}

.operators-list {
  padding: 20px 0;
  font-weight: bold;
}

.operator-item {
  padding: 8px 0px 0px 0px;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 4px;
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.operator-item:hover {
  background:#f0f0f0;
}

.message-input{
  /* position: fixed; */
  bottom: 0px;
  display: flex;
  border-top: 1px solid #dddfe5;
  width: 100%;
  padding: 14px 12px;
  background: var(--secondary-color);
}
@media (min-width:767px) and (max-width:768px){
  .message-input {
    padding-right: 35px;
  }
}

.overlay{
  position: fixed;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.7);
  z-index: 110;
  top: 0;
}

.overlay-content{
  overflow-y: auto;
  max-height: 100%;
  padding:22px;
  opacity: 1;
  background: var(--secondary-color);
  border: 1px solid #cbcbcb;
  width: 60%;
}
.createstorm-popup {
  padding: 0 !important;
  border: none;
  width: 50%;
}
@media(max-width:1024px){
  .overlay-content {
    width: 60%;
  }
}
@media(max-width:767px){
  .overlay-content {
    width: 90%;
    padding: 20px;
  }
  .install-icon{
    width:18px;
    display: inline;
    margin: 0 5px;
  }
  .modal-text p{
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--tertiary-color);
  }
}
.custom-type-messages {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.custom-type-messages-content {
  overflow-y: scroll;
  max-height: 400px;
  padding:20px;
  opacity: 1;
  background: var(--secondary-color);
  border: 1px solid #cbcbcb;
  width: 40%;
}

.custom-type-button {
  margin: 12px 0 0 12px;
}

.form-input{
  margin-top: 12px;
  margin-bottom: 12px;
  padding:18px;
  width:100%;
  border-radius: 2px;
}

.form-button{
  margin-right:4px;
}

.user-submit-button{
  background-color: #6210CC;
}

.create-channel-wrapper {
  align-items: baseline;
}

.channel-create-button{
  background-color: #62EEAB;
  width: 100%;
}

.create-channel-checkbox {
  width: auto;
  margin: 0 5px 0 10px;
}

.select-custom-type {
  padding: 12px;
}

.select-custom-type select {
  width: 100%;
  font-size: 16px;
  padding-top: 12px;
  outline: none;
  border: none;
  cursor: pointer;
}

.send-message-button{
  /* border-radius: 0px 4px 4px 0px; */
  margin-right:8px;
}

.control-button {
  background: inherit;
  padding:4px;
  margin-right:1px;
}

.error{
  background:rgb(26, 22, 22);
  display:flex;
  padding:24px;
  width:100%;
}

.file-upload-label, .user-profile-image-upload-label, .number-of-undelivered-members-btn {
  cursor: pointer;
}

.file-upload-label img{
  width: 32px;
  max-width: 32px;
  vertical-align: middle;
}

@media screen and (max-width: 767px){
  .file-upload-label img{
    width: 25px;
    max-width: 25px;
  }
}

.message-type-add {
  cursor: pointer;
  padding: 12px;
}

.message-type-label {
  margin: 0 10px;
}

.message-type-select {
  font-size: 16px;
  padding: 7px;
  outline: none;
  cursor: pointer;
}

.profile-image-fallback{
    width: 50px;
    background: var(--grayshade5-color);
    height: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-image{
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
  border: 1px solid #eee;
}

.typing-indicator {
  position: fixed;
  bottom:80px;
  background-color: var(--secondary-color);
  opacity: 0.8;
  margin-left: 20px;
  margin-top:20px;
  font-weight: 500;
}

.display-none {
  display: none;
}

.message-input-column {
  flex-direction: column;
}

.freeze-notification {
  position: sticky;
  top:0;
  width: 100%;
  line-height: 30px;
  opacity: 70%; 
  background-color: rgb(173,201,255);
  text-align: center;
}

.frozen-icon {
  width: 20px;
  margin-right: 10px;
}
/* 
.underline {
  width: 100%;
  border-bottom: 1px solid lightgray;
} */

.user-copied-message {
  position: fixed;
  right: 20px;
}

.image-size-error {
  width: 100%;
  background: rgba(255, 0, 0, 0.2);
  padding: 5px;
  margin: 5px 0;
  text-align: center;
}

.channel-header-wrapper {
  display: flex;
  justify-content: space-between;
}

.create-metadata-conteiner {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 12px;
}

.create-metadata-title {
  margin: 0;
  padding: 5px;
  font-size: 16px;
}

.create-metadata-btn {
  padding: 5px 10px;
}

.metadata-modal-input {
  display: flex;
  margin: 0 0 10px;
}

.choise-report-obj {
  cursor: pointer;
  color: #6210CC;
  text-decoration: underline;
}

.number-of-undelivered-members {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
}

.number-of-undelivered-message-btn:hover::after {
  content: attr(data-title);
  position: absolute;
  border: 1px solid gray;
  background-color: darkgray;
  border-radius: 5px;
  padding: 2px 4px;
  font-size: 10px;
}

.banned-muted-users-list {
  margin-top: 20px;
}

.show-users-status-btn {
  display: block;
  margin-top: 5px;
}

.user-online-status {
  margin: 0 0 0 5px;
}
.user-profile-title {
  margin-top: 0;
}

.user-profile-image-wrapper, .user-profile-nickname-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.user-profile-nickname-wrapper {
  margin: 0 0 10px;
}

.user-profile-image {
  border-radius: 50%;
  padding: 5px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.user-profile-nickname-input {
  width: auto;
}

.user-profile-nickname-button {
  border: none;
  background: transparent;
  color: black;
}

.user-profile-image-upload-label, .user-profile-nickname-button {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  padding: 5px;
}

.number-of-undelivered-members {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
}

.number-of-undelivered-message-btn:hover::after {
  content: attr(data-title);
  position: absolute;
  border: 1px solid gray;
  background-color: darkgray;
  border-radius: 5px;
  padding: 2px 4px;
  font-size: 10px;
}

.do-not-disturb-wrapper, .do-not-disturb-start-wrapper, .do-not-disturb-end-wrapper, .notifications-snooze-wrapper, .notifications-snooze-input-wrapper {
  display: flex;
}

.do-not-disturb-wrapper, .notifications-snooze-wrapper {
  flex-direction: column;
}

.do-not-disturb-wrapper, .do-not-disturb-start-wrapper, .do-not-disturb-end-wrapper, .notifications-snooze-wrapper, .notifications-snooze-input-wrapper {
  margin-bottom: 10px;
}

.notifications-overlay-content {
  padding: 40px;
  width: 50%;
  max-height: 500px;
}

.notification-modal-label, .notification-modal-input {
  margin-right: 10px;
}

.notification-modal-input {
  width: 40%;
}

.open-poll-btn {
  padding: 5px;
  background: transparent;
  color: black;
  margin-left: 2px;
}

.create-poll-modal, .create-poll-modal_inputs {
  display: flex;
  flex-direction: column;
}

.create-poll-modal {
  position: relative;
}

.create-poll-modal input {
  padding: 14px 32px 14px 6px;
}

.create-poll-modal_inputs {
  justify-content: space-between;
  margin-bottom: 10px;
}

.create-poll-modal .input_wrapper {
  margin-bottom: 5px;
}

.create-poll-modal .input_wrapper input[type="text"] {
  width: 60%;
}

.create-poll-modal h3 {
  text-align: center;
}

.option-add_btn {
  border: 1px solid #cbcbcb;
  border-radius: 0 4px 4px 0;
  font-size: 14px;
}

.poll-close_btn {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
}

.poll-create_btn {
  margin: 0 auto;
}

.poll-options {
  width: 100%;
}

.options_item {
  padding: 0 4px;
  margin-right: 4px;
  background: #cbcbcb;
  color: var(--secondary-color);
  border-radius: 4px;
  cursor: pointer;
}

.oc-members-list {
  margin-bottom: 80px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.scheduled-messages-list-modal {
  position: relative;
}

.close-button {
  background: none;
  padding: 0;
  position: absolute;
  top: 24px;
  right: 24px;
}

.update-input {
  display: flex;
}

.update-input input {
  width: 50%;
}

.scheduled-message-header {
  font-weight: 800;
  margin-bottom: 30px;
}

.schedule-message {
  margin-top: 20px;
}

.cancel-button {
  margin-right: 10px;
}

.scheduled-messages-settings-modal {
  z-index: 2;
}

.settings-header {
  margin-bottom: 30px;
  font-weight: 800;
}

.schedule-message-error {
  color: red;
  margin-bottom: 20px;
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]::after {
  content: attr(data-tooltip);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: 1s;
  pointer-events: none;
  padding: 2px;
  background-color: #424242;
  border-radius: 4px;
  font-size: 10px;
  width: max-content;
  z-index: 100;
}

[data-tooltip]:hover::after {
  opacity: 1;
  top: -1.5em;
}

.option-icon {
  visibility:hidden;
  width: 14px;
  filter: brightness(0) invert(1);
}

.option_wrapper:hover .option-icon {
  visibility: visible;
}

.create-poll-modal .option input[type="text"] {
  width: 55%;
}

.add-new-option {
  background: var(--secondary-color);
  border-radius: 4px;
  color: black;
  padding: 5px;
  font-size: 12px;
  margin-top: 10px;
}

.poll-message {
  position: relative;
}

.poll-status {
  position: absolute;
  bottom: 24px;
  right: 24px;
  font-size: 10px;
  padding: 5px;
}

.option_wrapper label, .add-new-option {
  margin-right: 10px;
}

.poll-status_wrapper {
  width: 100%;
  min-height: 25px;
}

.vote {
  margin-right: 5px;
  padding: 5px;
  font-size: 10px;
  background: var(--secondary-color);
  color: black;
  border-radius: 50%;
}

.pin-checkbox {
  display: inline-block;
}

.message-img {
  max-width: 100%;
}

.oc-message-container{
  white-space:pre-line;
}
.bgcolor-dark-blue{
  background: var(--primary-color);
}
.groupChatWidow{
  flex: 3 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.chat-input-box {
  border: none !important;
  padding: 0 10px;
  -webkit-appearance:none;
  min-height: 56px;
  height: auto;
  max-height: 180px;
  padding: 10px;
  max-width: 100%;
  padding-right: 120px;
  /* overflow-y: auto !important; */
}
@media(max-width:767px){
  .top-nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .chat-input-box {
    max-height: 120px;
    max-width: 79%;
    padding-right: 0px;
  }
}

@media screen and (max-width: 1023px){
  .chat-input-box{
    min-height: 48px;
  }
  .send-message-button{
    margin-right: 0;
    margin-left: 10px;
  }
}

.fileupload-view {
  
}
.kb-data-box {
  width: 100%;
  flex: 1;
}

.kb-modal-data-title {
  margin-bottom: 10px;
}
.kb-data-title h6 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
/* .kb-file-upload {
  margin-bottom: 20px;
} */
.kb-attach-box {
  min-height: 40px;
  margin: 0;
  padding-bottom: 15px;
}
.file-upload-box {
  /* background-color: #E3E3E3; */
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
  max-width: fit-content;
  padding: 6px 0 8px;
  padding-left: 2px;
}
.file-upload-box .file-upload-input {
  position: absolute;
  /* width: 100%; */
  /* height: 100%; */
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.file-link{
  margin-left: 0px;
}
.file-link img {
  width: 14px;
}
.file-validation-msg {
  font-size: 16px;
}
@media(max-width:767px){
  .file-link {
    font-size: 12px;
  }
  .file-link img {
    /* width: 24px; */
  }
  .file-validation-msg {
    font-size: 12px;
  }
}
@media(max-width:320px){
  .file-validation-msg {
    font-size: 10px;
  }
}
.file-atc-box {
  display: flex;
  align-items: center;
  /* margin-bottom: 15px; */
}
.file-image {
  /* width: 130px;
  min-width: 130px;
  height: 85px;
  min-height: 85px; */
  /* width: 130px; */
  /* height: 85px; */
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
  background-color: #eaecf1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #475f7b;
  padding: 3px;
}
.file-image img{
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}
.file-detail {
  flex: 1;
  width: calc(100% - 210px);
}
.file-detail a {
  word-break: break-all;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  /* margin-bottom: 8px; */
}
.file-detail p {
  font-size: 12px;
  color: #8194aa;
  line-height: initial;
  font-weight: 400;
  margin-bottom: 8px;
}
.file-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.file-action-btn {
  font-size: 12px;
  color: #8194aa;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
}
.file-action-btn:hover {
  color: #3d546f;
  text-decoration: underline;
}
.file-atc-box:last-child{
  margin-bottom: 0;
}
.brainstrom-logo {
  width: 70%;
}
.close-icon {
  padding: 0 7px;
}
.file-item .file-name {
  overflow-wrap: anywhere;
  max-width: 100%;
  margin-right: 10px;
}
.file-item .delete-file {
  max-width: 40px;
}
.loader-ct {
  width: 100%;
  height: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--loaderbg);
}
.channels-loader-ct {
  background-color: transparent !important;
}
.files-list {
  /* min-height: 40px; */
  position: relative;
  margin-top: 0px;
}

.form-appearance{
  -webkit-appearance:none;
}
.flexStrom{
  height: 100%;
  flex-wrap: wrap;
  overflow: hidden;
}
.chnamesidebar{
  max-width: 300px;
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.list-decimal::marker{
  font-weight: bold;
  color: var(--primary-color);
}
.swiper-pagination-bullet-active{
  background:var(--primary-color) !important;
}

@media(max-width:767px){
  .message-input{
    padding: 3px;
    position: fixed;
    bottom: 0;
  }
}
.my-custom-pagination-div{
  padding-bottom: 1.5rem;
}
.videoYoutube{
  position:relative;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

@media(max-width:767px){
  .videoYoutube{
   display: none;
  }
}
.suggestion-box {
  bottom: 68px;
  min-width: 230px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 6px;
}
@media(max-width:767px){
  .suggestion-box {
    bottom: 60px;
  }
}
.suggestion-box ul li {
  line-height: 30px;
}
.chat-input-box__suggestions {
    left: 0px !important;
    top: auto !important;
    bottom: 48px !important;
    /* padding: 10px; */
    border: 1px solid gray;
    border-radius: 5px;
    min-width: 230px !important;
    background: var(--secondary-color);
    z-index: 222;
}
.chat-input-box__suggestions ul li {
  line-height: 30px;
  padding: 6px 10px;
}
.chat-input-box__suggestions ul li.chat-input-box__suggestions__item--focused {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}
.chat-input-box__input {
  padding: 10px !important;
  padding: 11px 10px 10px !important;
}
@media(max-width:767px){
  .chat-input-box__input {
    /* line-height: 30px; */
    padding: 10px 10px 10px !important;
  }
}
@media(min-width:768px) and (max-width:912px){
  .chat-input-box__input {
    /* line-height: 30px; */
    padding: 10px 10px 10px !important;
  }
}
#storm_msg {
  overflow-y: auto !important;
  min-height: 56px;
  height: auto;
  /* max-height: 200px; */
  outline: none;
  padding: 15px 13% 13px 10px !important;
}

@media screen and (max-width: 1023px){
  #storm_msg {
    min-height: 48px;
    padding: 10px !important;
  }
}

#storm_msg::-webkit-scrollbar {
  display: none;
}

.message-input-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}
.message-input-buttons #send_message {
  height: 48px;
}
@media(max-width:767px){
  .message-input-buttons #send_message {
      height: auto;
      width: max-content;
      padding: 0;
      border-radius: 22px;
      margin: auto;
  }
  .message-input-buttons #send_message span img {
    width: 32px;
    transform: rotate(45deg);
    padding: 2px;
  }
}
::-webkit-input-placeholder,::placeholder {
  position:relative;
  white-space: pre-line;
  word-break: break-all;
  overflow-wrap: normal;
  word-break: break-word;
}
#send_message[disabled]{
  background-color: var(--btndisable);
}
#send_message[disabled] span img {
  opacity: 0.4;
}
@media(max-width:912px){
  #storm_msg::placeholder { /* Chrome/Opera/Safari */
    line-height: 20px !important;
    /* margin-top: 0px; */
    font-size: 14px;
    position: absolute;
    top: 4px;
  } 
}

thead{
  background: var(--grayshade4-color);
}
table,
tr,
td,
th {
  border: 1px solid #555;
  padding: 10px;
  margin: 10px 0;
  text-align: left;
}
code{
  white-space: pre-line;
}
.channel-card{
  min-height: 245px;
  /* width: 30%; */
  /* height: fit-content; */
  /* height: 251px; */
}

.channel-card .img-ct .tag{
  background: #DCF1D8;
  color: #74C265;
  font-size: 12px;
  font-weight: 400;
}

.public-channel-card.channel-card{
  width: 32%;
}

@media(max-width:1180px){
  .public-channel-card.channel-card {
    width: 48%;
  }
}

@media(max-width:768px){
  .public-channel-card.channel-card {
    width: 46%;
  }
}

@media(max-width:500px){
  .public-channel-card.channel-card {
    width: 100%;
  }
}

.channel-card .content-ct {
  width: 100%;
}

.channel-card .content-ct .tagTitle{
  background: var(--secondary-color);
  color: #7C7C85;
  font-weight: 400;
}

.channel-card .content-ct a{
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}


.channel-card .description {
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden; */
  min-height: 40px;
  min-height: 40px;
  font-weight: 500;
  font-size: 15px;
  line-height: 142.52%;
  letter-spacing: -0.02em;
  color: var(--graydark-color);
}

.channel-card .description{
    display: -webkit-box;
    /* -webkit-line-clamp: 3; */
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.main-txt {
  max-width: 600px;
}

@media(max-width:767px){
  .channel-card, .creator-card  {
    width: 45%;
    /* margin: 10px auto; */
  }
  .main-txt {
    max-width: 100%;
  }
}

@media(max-width:500px){
  .channel-card, .creator-card  {
    width: 100%;
    background: var(--secondary-color);
    margin: 10px auto;
  }
  .bot-lib.listing .channel-card{
    width: 90%;
  }
  .bot-lib .channel-card{
    width: 100%;
  }

  .bot-lib .bot-content-ct .channel-card:nth-last-child(1){
    /* margin-bottom: 130px; */
  }


}
@media(max-width:320px){
  .channel-card, .creator-card  {
    width: 44%;
  }
}
.channel-card .img-innet-ct {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}
.channel-card .img-innet-ct img {
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
@media(max-width:767px){
  .channel-card .img-innet-ct {
    width: 45px;
    height: 45px;
  }
  .channel-card .content-ct .start-convr-link {
    font-size: 14px;
    /* padding: 5px; */
  }
}
@media(min-width:768px) and (max-width:912px) {
  .channel-card {
    width: 95%;
  }
}
/* @media(min-width:913px) and (max-width:1024px) {
  .channel-card {
    width: 30%;
  }
} */
/* .channel-card .top-sec h5 {
  word-break: break-word;display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  font-weight: 700;
  font-size: 23.3684px;
  line-height: 28px;
  text-align: left;
} */
.bot-lib .tabs .tabitem__title{
  width: max-content;
}
.bot-lib .tabs-ct {
  width: 100%;
  overflow-x: auto;
  padding: 0px 0 20px 0;
  max-width: 70vw;
}

@media(max-width:767px){
  .bot-lib .tabs-ct {
      max-width: 95vw;
  }
}
.bot-lib .bot-content-ct {
  /* padding: 20px 0; */
  /* height: 90vh; */
  overflow-y: auto;
  width: 100%;
}

.bot-lib .sub-heading{
  color: var(--tertiary-color);
}

@media(max-width:1366px){
  .bot-lib .bot-content-ct {
    height: auto;
  }
}

@media(max-width:500px){
  .bot-lib{
    background: var(--secondary-color);
  }
  .bot-lib .bot-content-ct{
    background: var(--secondary-color);
  }
  .bot-lib .bot-content-ct .sub-heading {
    padding: 0;
    color: var(--tertiary-color);
    margin-bottom: 25px;
  }
  .channel .wrapper{
    padding-top: 0;
  }
  .channel-card .img-innet-ct {
    width: 35px;
    height: 35px;
  }
}
.tagTitle{
  font-size: 12px;
}
.bot-lib .tabs-ct::-webkit-scrollbar {
  height: 5px;
}

.bot-lib .tabs-ct::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.bot-lib .tabs-ct::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: #b3b0ad;
}

.bot-lib .tabs-ct::-webkit-scrollbar-thumb:hover {
  background-color: #b6b6b6;
}

.bot-lib .tabs-ct::-webkit-scrollbar:vertical {
  display: none;
}

.bot-lib .bot-content-ct::-webkit-scrollbar {
  width: 7px;
}

.bot-lib .bot-content-ct::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px grey;
}

.bot-lib .bot-content-ct::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #b3b0ad;
}

.bot-lib .bot-content-ct::-webkit-scrollbar-thumb:hover {
  background-color: #b6b6b6;
}
.hourglass::after {
  content: '⏳';
  --emoji: '⌛️';
  animation-name: twoFrames;
  animation-duration: 1.2s;
}
.emoji {
  font-size: calc(100px + 4vw);
  min-width: 1.4em;
  margin: 0.3em 0.4em;
  text-align: center;
}

.emoji::after {
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes twoFrames {
  50% {
      content: var(--emoji);
  }
}

.message  blockquote {
  padding: 15px;
  background: var(--secondary-color);
  margin: 15px;
}
.my-custom-pagination-div {
  padding-bottom: 0px !important;
}
#fileuplod_popup_wrapper h3{
  margin-top: 0px;
}
#fileuplod_popup_wrapper input {
  border: none;
  width: 100%;
  font-size: 14px;
  color: #838282;
  /* visibility: hidden; */
  height: 70px;
}
.input-file-ct {
  width: 100%;
  /* position: relative; */
  border-radius: 5px;
  /* height: 42px; */
}
#fileuplod_popup_wrapper .overlay-content {
  width: 45%;
}
#fileuplod_popup_wrapper textarea {
  resize: none;
  height: 90px;
}
@media(min-width:768px) and (max-width:1024px){
  #fileuplod_popup_wrapper .overlay-content {
    width: 60%;
  }
}
@media(max-width:767px){
  #fileuplod_popup_wrapper .overlay-content {
    width: 90%;
  }
}
@media(max-width:1024px){
  #fileuplod_popup_wrapper textarea {
    height: 115px;
  }
}
@media(max-width:768px){
  #fileuplod_popup_wrapper textarea {
    height: 136px;
  }
}
@media(max-width:400px){
  #fileuplod_popup_wrapper textarea {
    height: 160px;
  }
}
.choose-file-ct {
  /* position: relative; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* background: #f6f6f6;
 
  border-radius: 5px; */
}
@media(max-width:767px){
  .choose-file-ct {
    align-items: flex-start;
    flex-direction: column;
  }
  .choose-file-ct button {
    margin-top: 15px;
  }
}
.choose-file-ct.invisible {
  width: 80%;
}
.choose-file-ct button {
  /* position: absolute;
  z-index: 111;
  right: 8px; */
}
.choose-file-label {
  background: var(--secondary-color);
    /* border: 2px dashed #bbbbca; */
    border-radius: 5px;
    color: var(--primary-color);
    cursor: pointer;
    display: inline-block;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 1rem;
    outline: none;
    padding: 30px 10px;
    transition: all 0.3s;
    vertical-align: middle;
    position: absolute;
    z-index: 11;
    /* top: 5px; */
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: row;
    background: var(--secondary-color);
    height: 42px;
    border: 1px dashed #555;
}
.choose-file-label:hover {
  border-color : var(--primary-color);
  border-style : solid;
  /* box-shadow   : inset 0 1px 2px #888; */
  color        : var(--primary-color);
  background-color: var(--secondary-color);
}
@media(max-width:767px){
  .choose-file-label {
    width: 100%;
  }
  .input-file-ct {
    width: 100%;
    position: relative;
  }
  .files-list {
    width: 100%;
  }
}
@media(min-width:768px) and (max-width:1024px){
  .choose-file-label {
    width: 100%;
  }
  .input-file-ct {
    width: 100%;
    position: relative;
  }
  .files-list { 
    width: 75%;
  }
}
.msglistpopup-filelist {
  margin-top: 0px !important;
  /* min-height: 42px; */
  height: 42px;
}
.msglistpopup-filelist li {
  margin: 0 !important;
}
.file-name-link {
  max-width: 90% !important;
}
.loader-ct2 {
  max-width: 150px;
  width: 100%;
  height: inherit;
}
.uploadloader {
  display: block;
  content: "";
  position: relative;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: #ffffff;
  border-right-color: #ffffff;
  /* border-bottom-color: var(--secondary-color); */
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
.upload-file-error {
  margin-top: 4px !important;
  display: block;
}

.chat-typing-indicator {
  width: auto;
  border-radius: 50px;
  padding: 8px;
  display: table;
  margin: 20px 0 25px 20px;
  position: relative;
  -webkit-animation: 2s bulge infinite ease-out;
          animation: 2s bulge infinite ease-out;
}
.chat-typing-indicator:before, .chat-typing-indicator:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: -2px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  display: none;
}
.chat-typing-indicator:after {
  height: 7px;
  width: 7px;
  left: -10px;
  bottom: -10px;
}
.chat-typing-indicator span {
  height: 8px;
  width: 8px;
  float: left;
  margin: 0 3px;
  background-color: var(--primary-color);
  display: block;
  border-radius: 50%;
  opacity: 0.2;
}
.chat-typing-indicator span:nth-of-type(1) {
  -webkit-animation: 1s blink infinite 0.3333s;
          animation: 1s blink infinite 0.3333s;
}
.chat-typing-indicator span:nth-of-type(2) {
  -webkit-animation: 1s blink infinite 0.6666s;
          animation: 1s blink infinite 0.6666s;
}
.chat-typing-indicator span:nth-of-type(3) {
  -webkit-animation: 1s blink infinite 0.9999s;
          animation: 1s blink infinite 0.9999s;
}

@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}
@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}

.small-indicator{
  margin: 15px 0 0 -5px;
}

.chat-loading-ct .profile-image-ct {
    display: none;
}
.chat-loading-ct .profile-image-ct:first-child {
  display: flex;
}

.feature-slider {
  white-space: nowrap;
  overflow-x: auto;
}
.feature-slider::-webkit-scrollbar {
  display: none;
  width: none;
}
.featured-card  {
  max-height: 300px;
  /* width: 100%; */
  /* height: fit-content; */
  /* height: 251px; */
  /* max-width: 250px; */
  min-width: 210px;
}
.featured-card .content-ct {
  width: 100%;
}
.featured-card .content-ct a{
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--secondary-color);
}
.featured-card .description {
  min-height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 142.52%;
  letter-spacing: -0.02em;
  color: var(--graydark-color);
  display: -webkit-box;
  /* -webkit-line-clamp: 4; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}
.main-txt {
  max-width: 600px;
}

@media(max-width:767px){
  .featured-card  {
    width: 100%;
    /* margin: 10px auto; */
  }
  .main-txt {
    max-width: 100%;
  }
  .featured-card .description {
    /* -webkit-line-clamp: 5; */
  }
}

@media(max-width:500px){
  .featured-card  {
    width: 100%;
    margin-right: 15px !important;
    min-width:225px;
  }
}
@media(max-width:320px){
  .featured-card  {
    width: 190px;
  }
}
.featured-card .img-innet-ct {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}
.featured-card .img-innet-ct img {
  object-fit: cover;
  max-width: 100%;
  height: auto;
}
@media(max-width:767px){
  .featured-card .img-innet-ct {
    width: 48px;
    height: 48px;
  }
  .featured-card .content-ct .start-convr-link {
    font-size: 14px;
    /* padding: 5px; */
  }
}
@media(min-width:768px) and (max-width:912px) {
  .featured-card {
    width: 100%;
    min-width: 420px;
  }
}
.featured-content-ct .slick-track {
  display: flex;
}
.featured-content-ct .slick-list {
  display: flex;
  width: 100% !important;
}
.featured-content-ct .slick-arrow {
  display: none !important;
}
.create-storm-wrapper .button-ct button {
  padding: 0px 5px !important;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-left: 10px;
}
@media(max-width:992px){
  .create-storm-wrapper .button-ct button {
    margin-right: 0px;
    width: 120px;
    font-size: 14px;
  }
}
.recomended-card, .explore-bot-card {
  height: auto !important;
  max-height: 210px !important;
  min-height: 165px !important;
  width: 100%;
  max-width: 250px;
  min-width: 210px;
  margin-right: 15px;
  margin-left: 0px;
}

.recomended-card h3 {
  white-space: break-spaces;
  overflow-wrap: break-word;
  max-width: 70%;
}

.recomended-card .description {
  color: var(--graydark-color);
  font-size: 15px;
}

/* .recomended-bots-ct  .slick-slider, .explore-bot-wrapper .slick-slider{
  overflow-x: hidden !important;
}
.recomended-bots-ct .bot-content-ct  .slick-track, .explore-bot-wrapper .bot-content-ct  .slick-track {
  display: flex;
}
.recomended-bots-ct .bot-content-ct  .slick-list, .explore-bot-wrapper .bot-content-ct  .slick-list{
  display: flex;
  width: 100% !important;
  height: auto !important;
}
.recomended-bots-ct .bot-content-ct  .slick-arrow, .explore-bot-wrapper .bot-content-ct .slick-arrow {
  display: none !important;
} */
.recomended-bot-slider {
  white-space: normal;
  overflow-x: auto;
}
.recomended-bot-slider::-webkit-scrollbar {
  display: none;
  width: none;
}
@media(max-width:1024px){
  .explore-page .bot-category-wrapper h3 {
    width: 80%;
    word-break: break-word;
  }
}
@media(max-width:767px){
  .explore-page .bot-category-wrapper .channel-card {
    width: 48% !important;
    padding: 10px !important;
    min-height: fit-content;
  }
  .explore-page .bot-category-wrapper .channel-card:nth-child(odd) {
    margin-right: 10px;
    margin-left: 0;
  }
  .explore-page .bot-category-wrapper .bot-content-ct {
    width: 94%;
  }
  .explore-page .bot-category-wrapper h3 {
    width: 60%;
    word-break: break-all;
    font-size: 15px;
  }
}

@media(max-width:500px){
  .explore-page .bot-category-wrapper h3 {
    word-break: inherit;
    font-size: 14px;
  }
}

@media(max-width:360px){
    .explore-page .bot-category-wrapper h3 {
        min-width: 60%;
        width: auto;
        max-width: 75%;
        word-break: break-word;
    }
}
@media(max-width:360px){
  .explore-page .bot-category-wrapper .channel-card {
    width: 100% !important;
  }
  .explore-page .bot-category-wrapper .channel-card:nth-child(odd) {
    margin-right: 0px;
  }
}

.explore-bot-slider {
  white-space: normal;
  overflow-x: auto;
}
.explore-bot-slider::-webkit-scrollbar {
  display: none;
  width: none;
}

/* New navbar  */
.navbar {
  background-color: var(--brand-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  z-index: 30;
  position: fixed;
  width: 100%;
  }
  @media(max-width:767px){
    .navbar {
      z-index: 2;
      position: fixed;
    }
  }
  
  .navbar .logo {
  color: var(--secondary-color);
  text-decoration: none;
  font-size: 20px;
  }
  
  .navbar .nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  }
  
  .navbar .nav-links li {
  margin-right: 10px;
  }
  
  .navbar .nav-links li:last-child {
  margin-right: 0;
  }
  
  .navbar .nav-links li {
  color: var(--secondary-color);
  text-decoration: none;
  margin-right: 10px;
  cursor:pointer;
  }
  
  .navbar .search-box {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  width: 300px;
  height: 42px;
  }
  @media(min-width:768px) and (max-width:1024px){
    .navbar .search-box {
      width: 250px;
    }
  }
  .cl-sec {
    visibility: hidden;
  }

  .mob-footer {
    width: 100%;
    z-index: 10;
    height: 100px !important;
    bottom: 0;
    box-shadow: 0px 1px 5px #888, 0px 0px 1px #888;
  }
  .mob-footer .icon-ct svg{
    /* stroke: #616161; */
    /* stroke-width: 2; */
  }
  .mob-footer .icon-ct:hover  > .img-ct svg {
    fill:var(--primary-color);
    stroke: var(--primary-color);
  }
  .mob-footer .icon-ct:hover ~ .mob-footer .icon-ct span.menu-name {
    color:var(--primary-color);
  } 
  @media(max-width:767px){
    .chatbot-dashboard {
      padding-bottom: 70px;
      /* position: absolute; */
    }
  }
  
  .featured-content-ct .slick-slide {
    margin-right: 15px;
  }
  .recomended-bot-content-ct .slick-slide {
    margin-right: 15px;
  }
  .explore-bot-content-ct .slick-slide {
    margin-right: 15px;
  }
  @media(max-width:767px){
    .explore-page .slick-slider{
      background-color: var(--secondary-color);
    }
  }
  .user-name2{
    width: max-content;
  }

  .chatbot-dashboard {
    /* height: calc(100% - 80px); */
    overflow: hidden;
  }

  .navbar ul li .img-ct svg {
    stroke: var(--white-color);
  }
  .navbar ul li:hover > .img-ct svg{
    fill: var(--white-color);
  }
  .navbar ul li span {
    color: var(--white-color);
    font-weight: 600;
  }
  .navbar ul li:hover > span {
    color: var(--white-color);
  }
  .navbar .profile-navlink .main-link a span {
    color: #e8e8e8;
    font-weight: 600;
  }
  .navbar .profile-navlink .main-link a:hover > svg { 
    fill: var(--secondary-color);
  }
  .navbar .profile-navlink .main-link a:hover > span {
    color: var(--secondary-color);
  }
  /* .creator-profile-wrapper{
    background: var(--secondary-color);
  } */

  @media(max-width:600px){
    .creator-profile-wrapper .channel-card{
      margin: 8px 0 !important;
    }
  }

  .creator-card{
    /* max-height: 240px; */
    /* width: 46%; */
    /* max-width: none; */
  }

  @media screen and (max-width: 500px){
    .creator-card{
      width: 100% !important;
      margin: 10px auto !important;
    }
  }

  @media screen and (max-width: 950px){
    .creator-card{
      width: 100%;
      max-width: none;
    }
  }

  .creator-card .img-innet-ct{
    width: 48px;
    height: 48px;
  }

  .creator-card .description {
    font-size: 15px;
  }

 .selected-tab {
  border-bottom: 1px solid var(--primary-color);
 }

 .search-icon::-webkit-search-cancel-button{
  display: none;
 }

 .scroll-hidden::-webkit-scrollbar{
  display: none;
 }

 .pimage {
  width: 24px;
  height: 24px;
 }

 .breaker{
  border-right: 0.5px solid #cacaca;
 }
 
 /* bot profile */
 .bot-profile-inner .bot-name-wrapper .bot-name-ct h1{
  white-space: break-spaces;
  overflow-wrap: anywhere;
 }

 @media(max-width:360px){
  .bot-profile-inner .bot-name-wrapper .img-ct {
    width: 55px;
    height: 55px;
  }
  .bot-profile-inner .bot-name-wrapper .bot-name-ct h1{ 
    font-size: 18px;
  }
 }

 @media screen and (min-width: 768px) and (max-width: 950px){
  .search-cards .channel-card, .search-cards .creator-card {
    width: 98% !important;
  }
 }

 .channel-card h5 {
  white-space: break-spaces;
  overflow-wrap: break-word;
  max-width: 63%;
 }

 @media(max-width:500px){
  .channel-card h5{
    max-width: 52%;
  }
 }

 @media(max-width:375px){
  .channel-card h5{
    max-width: 50%;
  }
 }

 
 
#NotiflixConfirmWrap .notiflix-confirm-buttons{
  display: flex !important;
  flex-direction: row-reverse !important;
 }
 #NotiflixConfirmWrap .notiflix-confirm-buttons #NXConfirmButtonOk{
  float: right !important;
  margin: 0 0 0 2% !important;
  background-color: var(--brand-color) !important;
 }

 #NotiflixConfirmWrap .notiflix-confirm-buttons #NXConfirmButtonCancel{
  float: left !important;
  margin: 0 2% 0 0 !important;
 }

 @media(min-width:1200px){
  .explore-page .category-card {
      width: 30%;
  }
  .explore-page .featured-card {
    min-width: 422px;
    max-width: 430px;
  }
}

.explore-page .featured-card h3 {
    word-break: break-word;
}

.profile-pic {
  width:100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
}

.file-upload {
  display: none;
}
.circle {
  border-radius: 100% !important;
  overflow: hidden;
  width: 100px;
  height: 100px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  position: relative;
  /* top: 72px; */
}
img {
  max-width: 100%;
  height: auto;
}
.p-image {
position: relative;
color: #666666;
transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
}
.p-image:hover {
transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
}

.create-icon-ct svg{
  stroke: #616161
}

us-widget::slotted(div) form {

  margin-left: -50% !important;
}
/* #appRoot{

} */
#feedback-form {
  /* border: 1px solid red; */
}
.reGenratedata{
  position: absolute;
  bottom: -16px;
  right: 9px;
  font-size: 13px;
  background: var(--primary-color);
  color: var(--secondary-color);
  padding: 3px 7px;
  border-radius: 5px;
  cursor: pointer;
}
us-widget {
  display: none;
}

.choose-file-newlabel {
  border        : 2px dashed #555;
	border-radius : 7px;
	color         : #555;
	cursor        : pointer;
	display       : block;
	font-weight   : 600;
	margin        : 1em 0;
	padding       : 1.5em;
	text-align    : center;
	transition    : background 0.3s, color 0.3s;
}
.choose-file-newlabel:hover {
  border-color : var(--primary-color);
	border-style : solid;
	/* box-shadow   : inset 0 1px 2px #888; */
	color        : var(--primary-color);
  background-color: var(--secondary-color);
}
.disable-drawer-link {
    color: gray;
    pointer-events: none; 
    text-decoration: none; 
    cursor: not-allowed !important;
}
.disable-text {
  opacity: 0.5;
}
.ribbon-bg{
  background: url('/public/assets/images/upgrade-badge.svg');
  background-repeat: no-repeat;
  background-size: cover;
}
.message-item pre {
  background-color: #000 !important;
  margin-top: 0 !important;
}
.message-from-you pre {
  background-color: transparent !important;
}
.message-item pre code {
  text-shadow: none !important;
  color: var(--secondary-color) !important;
}
.message-from-you pre code {
  color: var(--tertiary-color) !important;
}

.message-item pre code span {
  background: none !important;
}
.message-item .copy-button {
  padding: 0;
  background: none;
}
.message-from-you p {
  white-space: break-spaces;
}
.web-search-tooltip::before {
  content: "";
  position: absolute;
  bottom: -14px; /* Adjust this value to position the arrow */
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: #1f2228 transparent transparent transparent; /* Adjust border-color */
}
.web-search-tooltip {
  visibility: hidden;
}

/* .web-status-link:hover + .web-search-tooltip {
  visibility: visible;
} */


.collapse-sidebar-wrapper .channel-list{
  width: 6.75%;
  height: 100vh;
  flex: initial !important;
}
.collapse-sidebar-wrapper .channel-list .channel-list-ct{
  height: 82vh !important;
}
.collapse-sidebar-wrapper .channel{
  width:92%;
}
.collapse-sidebar-wrapper .channel-list .sidebar-header h2{
  display: none;
}
.collapse-sidebar-wrapper .channel-list .search-header{
  display: none;
}
.collapse-sidebar-wrapper .channel-list .channel-list-item{
  border: none;
  background-color: transparent;
}
.collapse-sidebar-wrapper .channel-list .sidebar-header {
  justify-content: center;
}
.collapse-sidebar-wrapper .channel-list .sidebar-header > a {
  margin-top: 25px !important;
  margin-bottom: 20px !important;
  padding: 0 !important;
}
.collapse-sidebar-wrapper .channel-list .sidebar-header > a svg {
  transform: rotate(180deg);
  transition: 0.3s all;
}
.collapse-sidebar-wrapper .channel-list .channel-type{
  display: none;
}
.collapse-sidebar-wrapper .channel-list .channel-list-item .channel-list-item-name{
  justify-content: center;
}
.collapse-sidebar-wrapper .channel-list .channel-list-item .content-ct{
  display: none;
}
.collapse-sidebar-wrapper .channel-list .channel-list-item .msg-cnt {
  display: block;
}
.collapse-sidebar-wrapper .channel-list .channel-list-item .img-ct{
  margin: 6px !important;
}
.collapse-sidebar-wrapper .channel-list .channel-list-item.active .img-ct{
  box-shadow: rgb(44, 45, 152) 1px 1px 6px 1px;
  border: 1px solid var(--secondary-color);
}
.collapse-sidebar-wrapper .welcome-txt-ct {
  display: none;
}
.collapse-sidebar-wrapper .collapse-link {
  transform: translateX(-50%);
  left: 50%;
  padding: 0;
  width: fit-content;
}
.collapse-sidebar-wrapper .collapse-link img {
  transform: rotate(180deg);
  transition: 0.3s all;
}
.collapse-sidebar-wrapper .welcome-sidebar-wrapper {
  padding: 0 15px !important;
}
@media(min-width:768px) and (max-width:1024px){
  .collapse-sidebar-wrapper .channel-list > div:first-child {
    width: auto !important;
  }
  .collapse-sidebar-wrapper .channel-list {
    width: 12%;
  }
}
@media(min-width:820px){
  .collapse-sidebar-wrapper .channel-list-ct {
    overflow-y: scroll;
  }
  .collapse-sidebar-wrapper .channel-list-ct::-webkit-scrollbar {
    background: var(--secondary-color); 
    width: 4px;
  }
  .collapse-sidebar-wrapper .channel-list-ct::-webkit-scrollbar-thumb {
    background-color: var(--secondary-color);
  }
  .channel-list-ct:hover::-webkit-scrollbar-thumb {
    background-color: #cacaca;
  }
}

#desc_text  {
  resize: none; 
  overflow-y: hidden; 
  height: auto; 
  width: 100%; 
  padding: 10px; 
  box-sizing: border-box; 
  word-break: break-word;
  font-size: 15px;
  border: 1px solid #E7E7E7;
}
.bot-desc-para {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: pre-line;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
}
.explore-featured-wrapper .channel-card {
  margin-right: 10px;
  min-width: 48%;
}
.explore-featured-wrapper .channel-card .description {
  text-wrap: wrap !important;
  white-space: normal !important;
}
@media(min-width:768px) and (max-width:1024px){
  .explore-featured-wrapper .channel-card {
    min-width: 47%;
  }
}
@media(min-width:768px) and (max-width:992px){
  .explore-featured-wrapper .channel-card {
    min-width: 90%;
  }
}
@media(max-width:767px) {
  #feature_sliderr {
    padding-right: 5px !important;
  }
  .explore-featured-wrapper .channel-card {
    min-width: 100%;
    margin-right: 0px;
  }
}
@media(max-width:450px){
  .message-info .message-sender-name {
    max-width: 130px;
  }
  .message-from-you .message-sender-name {
    max-width: 160px;
  }
  .message-info .msg-time {
    white-space: nowrap;
  }
}
@media(max-width:400px){
  .message-info .message-sender-name {
    max-width: 115px;
  }
  .message-from-you .message-sender-name {
    max-width: 145px;
  }
}
@media(max-width:380px){
  .message-info .message-sender-name {
    max-width: 105px;
  }
  .message-from-you .message-sender-name {
    max-width: 135px;
  }
}
@media(max-width:370px){
  .message-info .message-sender-name {
    max-width: 95px;
  }
  .message-from-you .message-sender-name {
    max-width: 125px;
  }
  .message-info .msg-time {
    font-size: 15px;
  }
}
@media(max-width:350px){
  .message-info .msg-time {
    white-space: normal;
  }
}
@media(min-width:768px) and (max-width:992px){
  .message-info .msg-time {
    white-space: nowrap;
  }
  .message-info .message-sender-name {
    max-width: 160px;
  }
  .message-from-you .message-sender-name {
    max-width: 190px;
  }
}

#searchpage_searchField {
  background: var(--secondary-color);
  color: var(--tertiary-color);
}
.sidebar-header svg, .welcome-sidebar-wrapper svg {
  stroke: var(--primary-color);
}
.web-search-btn{
  border: 1px solid var(--radioBorder)!important;
}
.web-search-btn::after {
  background-color: var(--grayshade2-color) !important;
  border-color: var(--radioBorder) !important;
}
.notification-radio-icon {
  border: 1px solid var(--radioBorder)!important;
}
.notification-radio-icon::after  {
  background-color: var(--grayshade2-color) !important;
  border-color: var(--radioBorder) !important;
}
.fileupload-dropdown-icon circle, .fileupload-dropdown-icon path{
  stroke: var(--primary-color);
}
/* .share-bot-link-ct svg:nth-child(-n+3) path {
  fill:var(--primary-color) !important;
} */
.share-bot-link-ct svg:nth-last-child(-n+2) path {
  stroke: var(--primary-color) !important;
}
.mob-footer-icon-active {
  fill: var(--primary-color);
  stroke: var(--primary-color);
}
.mob-footer span {
  font-family: sans-serif !important;
}
.breadcrumbs-ct ul li, .breadcrumbs-ct ul span {
  color:  var(--graydark-color) !important;
}
.create-channel-icon path {
  fill: white;
}
.thunder-img {
  opacity: var(--imgopacity);
}
.upload-android-icon {
  fill: var(--secondary-color);
  display: inline-block !important;
}
.upload-android-icon path {
  fill:  var(--tertiary-color) !important;
}
.swiper-pagination-bullet {
  background-color: var(--graydark-color) !important;
}
.edit-msg-icon, .delete-msg-icon {
  width: 17px;
  height: 17px;
}
.edit-msg-icon path, .delete-msg-icon path {
  fill: var(--graydark-color) !important;
}
.notiflix-confirm-content, #NotiflixReportWrap .notiflix-report-content {
  background-color: var(--secondary-color) !important;
}
.notiflix-confirm-head > h5{
  color: var(--primary-color) !important;
}
.notiflix-confirm-head > div, #NotiflixReportWrap .notiflix-report-title, #NotiflixReportWrap .notiflix-report-message {
  color: var(--tertiary-color) !important;
}
.notiflix-confirm-buttons > .nx-confirm-button-cancel {
  background-color: var(--popupBtncolor) !important;
} 
.sidebar-visible {
  width: 0px;
  transition: 0.3s all;
}
.collapse-memberlist-wrapper {
  width: 250px;
  transition: 0.3s all;
}
@media (max-width: 1024px) {
  .collapse-memberlist-wrapper {
      width: 250px !important;
  }
}

@media (max-width: 900px) {
  .collapse-memberlist-wrapper {
      width: 210px !important;
  }
}

@media (max-width: 767px) {
  /* Full width for mobile devices */
  .collapse-memberlist-wrapper {
      width: 100% !important;
  }
}
.try-asking-ct {
    scrollbar-width: thin; /* for Firefox */
    scrollbar-color: var(--grayshade3-color) var(--grayshade2-color);
}
.right-sidebar-tabs ul {
  border-bottom: 1px solid var(--grayshade4-color);
}
.right-sidebar-tabs ul li{
  position: relative;
  padding: 12px 8px;
}
.right-sidebar-tabs ul .divider {
  width: 1px;
  height: 30px;
  background-color: var(--grayshade4-color);
}
.right-sidebar-tabs ul li.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
}
.files-ct .file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: pre-line;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.storm-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: pre-line;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.storm-edited-name {
  overflow-wrap: break-word;
}

.border-top-85::after {
  content: '';
  height: 0.5px;
  width: 85%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #e5e5e5;
}

.dropdown-svg-container svg{
  width: 18px;
  height: 18px;
}

.text-tertiary-color.dropdown-svg-container svg{
  width: 15px;
  height: 15px;
}

/* This keyframe will create the initial slide animation  */
@keyframes expanding {
  0% {
      transform: scaleX(0);
      opacity: 0;
  }
  100% {
      transform: scaleX(1);
      opacity: 1;
  }
}

/* This keyframe will create the loading gradient effect */
@keyframes moving {
  0% {
      background-position: 0 0;
  }
  100% {
      background-position: -200% 0;
  }
}

.loading-bar {
  height: 1.25rem;
  transform-origin: left; /* Without this, you'll notice the `expanding` animation starts expanding from the  middle instead of shooting out from the left */
  animation: expanding 0.4s 0.7s forwards linear, moving 1s 1s infinite forwards linear;
  border-radius: 0.125rem;
  background-image: linear-gradient(to right, #eff6ff 30%, var(--primary-color )60%, #eff6ff);
  background-size: 200% auto;
  opacity: 0; /* The `expanding` animation will fade in the loading bar */
  animation-delay: 100ms;
}
.side-load-icon {
  background: var(--gray-color);
}

@media(min-width:768px) and (max-width:992px){
  .createstorm-popup {
    width: 75%;
  }
}
.provider-ct, .model-ct {
  position: relative;
  display: flex;
  flex-direction: row !important;
}
#provider, #model {
    -webkit-appearance: none; 
    -moz-appearance: none;
    appearance: none; 
}
.provider-ct .select-dropdown::after , .model-ct .select-dropdown::after {
  content: '\203A';
  transform: rotate(90deg) translateY(-50%) !important;
  position: absolute;
  right: 25px;
  top: 10%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 28px;
  color: var(--tertiary-color);
}
#provider::-ms-expand {
  display: none;
}
@media(max-width:767px){
  .sidebar-visible {
    position: fixed !important;
    z-index: 110;
    transition: 0.2s ease-in-out; 
    height: 100%;
    top: 0px;
    margin-left: 100%;
    min-width: 300px;
    background-color: var(--gray-color);
  }
  .collapse-memberlist-wrapper {
    transition: 0.3s ease-in-out;
    margin-left: 0%;
  }
}
.fillcolor {
  fill: var(--primary-color) !important;
}
.onboading-feature-slide ul li svg {
  width: 30px;
  height: 30px;
  margin: auto;
}
.ReactModal__Overlay {
  z-index: 100;
  background-color: rgba(0,0,0,0.75) !important;
}
.ReactModal__Overlay .ReactModal__Content {
  width: 95%;
  max-width: 550px;
  background-color: var(--secondary-color) !important;
}
.ReactModal__Overlay .ReactModal__Content .modal-close {
  position: absolute;
  top: 6px;
  right: 14px;
  font-weight: 800;
  font-size: 20px;
}
.ReactModal__Content form input[type="radio"]
{
  appearance: none;
  display: none;
}

.ReactModal__Content form input[type="radio"] + label
{
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  line-height: 20px;
  margin: 0;
}

.ReactModal__Content form input[type="radio"] + label::before
{
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  aspect-ratio: 1;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 1;
  transition: all 0.3s;
  box-sizing: border-box;
}

.ReactModal__Content form input[type="radio"] + label::after
{
  content: "";
  display: inline-block;
  width: 9.5px;
  height: 9.5px;
  /* aspect-ratio: 1; */
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  border-radius: 50%;
  position: absolute;
  left: .28rem;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.3s;
}
@media(max-width:767px){
  .ReactModal__Content form input[type="radio"] + label::after {
    width: 10px;
    height: 10px;
  }
}
.ReactModal__Content form input[type="radio"]:checked + label::after
{
  opacity: 1;
}
.report-fillcolor {
  stroke:var(--primary-color) !important;
}
@media(max-width:767px){
  .message{
    max-width: 100%;
  }
}
@media(max-width:450px){
  .message-info .message-sender-name {
    max-width: 130px;
  }
  .message-from-you .message-sender-name {
    max-width: 160px;
  }
  .message-info .msg-time {
    /* white-space: nowrap; */
  }
 .message-user-info {
    align-items: baseline !important;
  }
}
@media(max-width:400px){
  .message-info .message-sender-name {
    max-width: 115px;
  }
  .message-from-you .message-sender-name {
    max-width: 145px;
  }
}
@media(max-width:380px){
  .message-info .message-sender-name {
    max-width: 105px;
  }
  .message-from-you .message-sender-name {
    max-width: 135px;
  }
}
@media(max-width:370px){
  .message-info .message-sender-name {
    max-width: 95px;
  }
  .message-from-you .message-sender-name {
    max-width: 125px;
  }
  .message-info .msg-time {
    font-size: 15px;
    white-space: normal;
  }
}
@media(max-width:350px){
  .message-info .msg-time {
    white-space: normal;
  }
}
@media(min-width:768px) and (max-width:992px){
  .message-info .msg-time {
    white-space: nowrap;
  }
  .message-info .message-sender-name {
    max-width: 160px;
  }
  .message-from-you .message-sender-name {
    max-width: 190px;
  }
  .message-user-info {
    align-items: baseline !important;
  }
}
.menuDrawer ul li {
  list-style-type: none !important;
}
@media(min-width:768px){
  .chat-tools-menu ul {
    max-height: 270px;
    overflow: auto !important;
    background-color: var(--secondary-color);
  }
  .chat-tools-menu ul::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  .chat-tools-menu ul::-webkit-scrollbar-track {
    border-radius: 20px;
    overflow: hidden;
    padding-right: 10px;
    background: var(--lightGreyMessage); 
  }
  
  /* Handle */
  .chat-tools-menu ul::-webkit-scrollbar-thumb {
    background: var(--grayshade3-color); 
    border-radius: 30px;
    overflow: hidden;
  }
}
@media(max-width:767px){
  .chat-tools-menu ul {
    max-height: 265px;
    overflow: auto;
  }
}

.chat-input-box__suggestions {
  overflow: hidden;
}
.chat-input-box__suggestions__list li {
  color: var(--tertiary-color) !important;
  background-color: var(--secondary-color);
}
.chat-input-box__suggestions__list li:hover, .chat-input-box__suggestions__list .chat-input-box__suggestions__item--focused {
  color: #000000 !important;
}
.mob-footer-chat-icon-active {
  fill: transparent;
  stroke: var(--primary-color) !important;
}
.mob-footer-chat-icon-inactive {
  fill: var(--primary-color);
  stroke: var(--primary-color);
}

/* ------Top header offer--------  */
.disclaimer-strip p {
  font-size: 13px;
  color: var(--primary-color);
  margin-bottom: 0px !important;
}
@media(max-width:1200px){
  .disclaimer-strip {
    overflow: hidden; 
    white-space: nowrap;
    margin: 0px; 
  }

  .disclaimer-strip p {
    font-size: 12px;
    padding: 0px 10px 5px;
    display: inline-block;
    animation: scroll-left 40s linear infinite; 
    margin:0;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: var(--primary-color);
    margin-bottom: 0px !important;
  }

  @keyframes scroll-left {
    0% {
      transform: translateX(100%); 
    }
    100% {
      transform: translateX(-100%); 
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    @keyframes scroll-left {
      0% {
        transform: translateX(50%); 
      }
      100% {
        transform: translateX(-100%); 
      }
    }
  }

  @keyframes scroll-left {
    0% {
      transform: translateX(25%); 
    }
    100% {
      transform: translateX(-100%); 
    }
  }
    .disclaimer-strip p {
      font-size: 12px;
      padding: 0px 10px 5px;
      animation: scroll-left 45s linear infinite;
  }
}

@media(min-width:1700px){
  .disclaimer-strip {
    margin: 0 110px;
  }
  .disclaimer-strip p {
    font-size: 14px;
  }
}

.webstatus-loader {
  border-top-color: var(--primary-color);
  border-right-color: var(--primary-color);
}